import styled, { css } from 'styled-components'
import { Subtitle, IPColor } from '@components/LPCard/Common'

export const SubContainer = styled.div<IPColor & { shadowed?: boolean }>`
  padding: ${({ theme }) => theme.space.xs};
  position: relative;
  text-align: center;
  width: 100%;

  ${({ theme, color = 'white' }) => css`
    background-color: ${theme.palette[color]};
  `}

  ${({ shadowed }) =>
    shadowed &&
    css`
      border-radius: ${({ theme }) => theme.radius.md};
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
    `}

  span[data-key='icon-IconInformationCircle'] {
    font-size: ${({ theme }) => theme.fontSize.xs};
    position: absolute;
    z-index: 1;
    left: -6px;
    top: -6px;
  }

  .narrow {
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
  }
`

export const Description = styled(Subtitle)`
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  letter-spacing: 0.42px;
`

export const SelectContaier = styled.div`
  width: 100%;
  > div > label {
    font-size: ${({ theme }) => theme.fontSize.xxxs};
  }

  .ant-select {
    width: 100%;
  }
`

export const SubTitle = styled.div<IPColor>`
  display: flex;
  line-height: 22px;
  letter-spacing: 0.56;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};

  svg {
    font-size: 20px;
    ${({ theme, color = 'white' }) => css`
      color: ${theme.palette[color]};
    `}
  }

  > span:last-child {
    top: ${({ theme }) => theme.space.xxs};
    left: ${({ theme }) => theme.space.xxs};
    position: relative;
  }

  ${({ theme, color = 'white' }) => css`
    color: ${theme.palette[color]};
  `}

  align-items: center;
  justify-content: center;
`

export const SubDescription = styled.div<IPColor>`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxs};

  svg {
    font-size: 20px;
    ${({ theme, color = 'white' }) => css`
      color: ${theme.palette[color]};
    `}
  }

  > span:last-child {
    top: ${({ theme }) => theme.space.xxs};
    left: ${({ theme }) => theme.space.xxs};
    position: relative;
  }

  > div {
    justify-content: center;
    width: 100%;
  }

  ${({ theme, color = 'white' }) => css`
    color: ${theme.palette[color]};
  `}
`

export const UnlimitedLCSDesc = styled.div`
  padding: 0 ${({ theme }) => theme.space.xs};
`

export const Link = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.colors.brandPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.4s;
`

export const PFSubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  align-self: inherit;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.white};
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Align items horizontally */
  gap: 8px; /* Space between text and icon */
`
